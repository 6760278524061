<template>
    <Setup-Step
        @yes="f => addNew()"
        @no="next"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="courier-journeys"
        title="Regular Journeys">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="journey-templates"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.journeyName }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    <BT-Entity navigation="locations" :itemValue="item.startLocationID" itemText="locationName" inline />
                                        -->
                                    <BT-Entity navigation="locations" :itemValue="item.endLocationID" itemText="locationName" inline />
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn small text @click="addNew(item)">change</v-btn>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>
                    <p class="my-4">Would you like to add a regular journey?</p>
                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        label="Journey Name"
                        v-model="newItem.journeyName"
                        isEditing />
                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>Where will this journey depart from?</p>

                    <BT-Select-List-Box
                        @change="stage += 1"
                        v-model="newItem.startLocationID"
                        :canDeselect="false"
                        height="50vh"
                        isEditing
                        isSelectingNullValue
                        itemText="locationName"
                        label="Starting Location"
                        navigation="locations" />
                
                    <BT-Btn
                        v-if="newItem.startLocationID != null"
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>Where will this journey finish?</p>

                    <BT-Select-List-Box
                        @change="stage += 1"
                        v-model="newItem.endLocationID"
                        :canDeselect="false"
                        height="50vh"
                        isEditing
                        isSelectingNullValue
                        itemText="locationName"
                        label="Finishing Location"
                        navigation="locations" />
                
                    <BT-Btn
                        v-if="newItem.endLocationID != null"
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>What about other limits?</p>

                    <BT-Field-Number
                        v-model.number="newItem.maxDestinations"
                        label="Max Destinations"
                        isEditing />

                    <BT-Field-Number
                        v-model.number="newItem.maxWeight"
                        label="Max Weight"
                        isEditing />

                    <BT-Field-Number
                        v-model.number="newItem.maxPackages"
                        label="Max Packages"
                        isEditing />

                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>
                
                <v-tab-item>
                    <p>Will a specific driver always be doing this journey?</p>

                    <BT-Select-List-Box
                        @change="save(newItem)"
                        v-model="newItem.driverUserID"
                        :canDeselect="false"
                        height="50vh"
                        isEditing
                        isSelectingNullValue
                        itemText="userName"
                        label="Driver"
                        navigation="users" />
                
                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="save(newItem)" />
                </v-tab-item>

                <v-tab-item>
                    <p>{{ newItem.journeyName }} Coverage</p>
                    <GmapMap
                        ref="locMap"
                        :center="center"
                        :zoom="7"
                            style="width: 100%; height: 65vh;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">

                            <gmap-polygon
                                v-for="(pointer, index) in journeyPointers"
                                @paths_changed="($event) => { updatePointerPaths($event, pointer) }"
                                clickable
                                editable
                                :fillOpacity="0.5"
                                :key="index + 'b'"
                                :paths="pointer.boundary"
                                :strokeWeight="1" />
                    </GmapMap>

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Done"
                        @click="savePointers" />
                </v-tab-item>
            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Journey-Setup',
    components: {
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            center: { lat: -38, lng: 144 },
            journey: null,
            journeyPointers: [],
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j) {
            this.newItem = j || {};
            this.stage += 1;
        },
        next(nextFunc) {
            nextFunc();
        },
        async save(item) {
            try {
                this.loadingMsg = 'Saving';
                
                var res = null;

                if (item.id == null) {
                    res = await this.$BlitzIt.store.post('journey-templates', item);
                    this.journeyPointers.push({
                        id: null,
                        rowVersion: null,
                        pointerName: item.journeyName + ' Pointer',
                        boundary: this.getAreaAround(this.center, 1),
                        weekdays: 'Always',
                        journeyTemplateID: res.id
                    });
                }
                else {
                    res = await this.$BlitzIt.store.patch('journey-templates', item);
                    this.journeyPointers = await this.$BlitzIt.store.getAll('journey-pointers', { templateID: res.id });
                }
                
                item.id = res.id;
                item.rowVersion = res.rowVersion;

                this.journey = item;
                this.stage +=1;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async savePointers() {
            try {
                this.loadingMsg = 'Saving';

                for (let i = 0; i < this.journeyPointers.length; i++) {
                    const pointer = this.journeyPointers[i];
                    
                    if (pointer.changed == true || pointer.id == null) {
                        var res = null;
                        if (pointer.id == null) {
                            res = await this.$BlitzIt.store.post('journey-pointers', pointer);
                        }
                        else {
                            res = await this.$BlitzIt.store.patch('journey-pointers', pointer);
                        }

                        pointer.changed = false;
                        pointer.id = res.id;
                        pointer.rowVersion = res.rowVersion;
                    }
                }

                this.refreshList = !this.refreshList;
                this.stage = 0;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        updatePointerPaths(mvcArray, zone) {
            let paths = [];
            for (let i=0; i<mvcArray.getLength(); i++) {
              let path = [];
              for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
              }
              paths.push(path);
            }
            zone.boundary = paths[0];

            zone.changed = true;
        },
    }
}
</script>